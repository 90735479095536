<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Mode Logs</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="betLevelSelect"
        :items="betLevelItems"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Bet Level"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="dates"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dates"
            label="Picker in menu"
            prepend-icon="mdi-calendar"
            readonly
            single-line
            hide-details
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="dates" locale="tr-TR" range no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false">
            Cancel
          </v-btn>
          <v-btn text color="primary" @click="initialize">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-combobox
        v-model="typeSelect"
        :items="gameType"
        :loading="$store.state.progress"
        item-value="key"
        item-text="value"
        @change="initialize"
        hide-details
        label="Mode Type"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-combobox
        v-model="pageItemSelect"
        :items="pageItems"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Page"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark @click="initialize" class="mb-2 ma-2"
        >Search</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="modeLogsDataTable"
      :items="$store.state.modeLogs.all.data"
      :items-per-page="pageItemSelect.id"
      hide-default-footer
      :loading="loading"
      :options.sync="options"
      loading-text="Loading... Please wait"
      class="elevation-1"
    >
      <template v-slot:item.createDate="{ item }">
        <div>{{ new Date(item.createDate * 1000) | formatDate }}</div>
      </template>
      <template v-slot:item.bl="{ item }">
        <div>{{ item.bl | formatMoney }}</div>
      </template>
      <template v-slot:item.fs="{ item }">
        <div>{{ item.fs | formatMoney }}</div>
      </template>
      <template v-slot:item.t="{ item }">
        <div>{{ item.t | formatMoney }}</div>
      </template>
      <template v-slot:item.e="{ item }">
        <v-icon small @click="extraProperties(item)">mdiJson</v-icon>
      </template>
      <template v-slot:item.sr="{ item }">
        <v-icon small @click="spinResult(item)">mdiJson</v-icon>
      </template>
      <template v-slot:item.mn="{ item }">
        <div>
          {{
            item.m == 1
              ? "Magic Bust"
              : item.m == 2
              ? "Olympos God"
              : item.m == 3
              ? "Cosmic Jelly"
              : item.m == 4
              ? "Lucky Clover"
              : item.m == 5
              ? "Candy Crown"
              : item.m == 6
              ? "Japanese"
              : item.m == 7
              ? "Viktorie Of Zeus"
              : item.m == 8
              ? "Pirate Bounty"
              : item.m == 9
              ? "777 Classic"
              : item.m == 10
              ? "Super Vegas"
              : item.m == 11
              ? "Juicy Fruits"
              : item.m == 12
              ? "Wild West Duel"
              : item.m == 13
              ? "Xmash Cash"
              : item.m == 14
              ? "Golden Frog"
              : item.m == 15
              ? "Donut Bakery"
              : item.m == 16
              ? "Aqua Paradise"
              : item.m == 17
              ? "Wild Forest"
              : "Check Mode Name"
          }}
        </div>
      </template>
      <template v-slot:item.u="{ item }">
        <UserDetailCard
          :userId="item.u"
          :pictureUrl="item.pu"
          :userName="item.un"
          :level="item.l"
          :flag="item.f"
        />
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="currentPage"
        @input="pageClick"
        :total-visible="20"
        :length="$store.state.modeLogs.all.totalPage"
      ></v-pagination>
    </div>
    <v-dialog v-model="dialogExtraProperties" max-width="1600px">
      <v-card>
        <v-card-title>
          <span class="headline">Extra Properties</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogExtraProperties = false"
            >Close</v-btn
          >
        </v-card-actions>
        <v-card-text>
          <json-viewer
            name="df"
            :value="itemExtraProperties"
            :expand-depth="5"
            theme="my-awesome-json-theme"
            sort
          ></json-viewer>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSpinResult" max-width="1600px">
      <v-card>
        <v-card-title>
          <span class="headline">Spin Result</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogSpinResult = false"
            >Close</v-btn
          >
        </v-card-actions>
        <v-card-text>
          <json-viewer
            name="df"
            :value="itemSpinResult"
            :expand-depth="5"
            theme="my-awesome-json-theme"
            sort
          ></json-viewer>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import "@/assets/css/site.css";
import logType from "@/lib/logType";
import actionType from "@/lib/actionType";
import gameType from "@/lib/gameType";

export default {
  components: {
    UserDetailCard: () => import("@/components/Users/UserDetailCard.vue"),
  },

  name: "ModeLogListCard",
  props: {
    modeType: {
      type: String,
    },
    betLevel: {
      type: Number,
    },
    size: {
      type: Number,
    },
  },
  data() {
    return {
      dates: [
        new Date(Date.now())
          .addDays(-365)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now()).toISOString().substr(0, 10),
      ],
      menu: false,
      dialog: false,
      dialogExtraProperties: false,
      itemExtraProperties: {},
      dialogSpinResult: false,
      itemSpinResult: {},
      pageCount: 0,
      typeSelect: { key: -999, value: "All" },
      betLevelSelect: { id: -999, value: "All" },
      searchTypeSelect: { id: -1, value: "All" },
      pageItemSelect: { id: 50, value: "50" },
      loading: false,
      currentPage: 1,
      actionType: actionType,
      gameType: gameType,
      logType: logType,
      options: {},
      detail: {},
      jsonOptions: {
        mode: "code",
      },
      pagination: {
        sortBy: "name",
      },

      pageItems: [
        { id: 50, value: "50" },
        { id: 100, value: "100" },
        { id: 250, value: "250" },
        { id: 500, value: "500" },
        { id: 1000, value: "1000" },
        { id: 2000, value: "2000" },
        { id: 5000, value: "5000" },
        { id: 10000, value: "10000" },
      ],
      betLevelItems: [
        { id: -999, value: "All" },
        { id: 100, value: "100" },
        { id: 300, value: "300" },
        { id: 500, value: "500" },
        { id: 1000, value: "1000" },
        { id: 1500, value: "1500" },
        { id: 2000, value: "2000" },
        { id: 2500, value: "2500" },
        { id: 4000, value: "4000" },
        { id: 5000, value: "5000" },
        { id: 7500, value: "7500" },
        { id: 10000, value: "10000" },
        { id: 15000, value: "15000" },
        { id: 20000, value: "20000" },
        { id: 25000, value: "25000" },
        { id: 30000, value: "30000" },
        { id: 40000, value: "40000" },
        { id: 50000, value: "50000" },
        { id: 60000, value: "60000" },
        { id: 75000, value: "75000" },
        { id: 100000, value: "100000" },
        { id: 125000, value: "125000" },
        { id: 150000, value: "150000" },
        { id: 175000, value: "175000" },
        { id: 200000, value: "200000" },
        { id: 250000, value: "250000" },
        { id: 300000, value: "300000" },
        { id: 350000, value: "350000" },
        { id: 400000, value: "400000" },
        { id: 450000, value: "450000" },
        { id: 500000, value: "500000" },
        { id: 600000, value: "600000" },
        { id: 750000, value: "750000" },
        { id: 800000, value: "800000" },
        { id: 1000000, value: "1000000" },
        { id: 1500000, value: "1500000" },
        { id: 2000000, value: "2000000" },
        { id: 2500000, value: "2500000" },
        { id: 4000000, value: "4000000" },
        { id: 5000000, value: "5000000" },
        { id: 6000000, value: "6000000" },
        { id: 7500000, value: "7500000" },
        { id: 10000000, value: "10000000" },
        { id: 15000000, value: "15000000" },
        { id: 20000000, value: "20000000" },
        { id: 25000000, value: "25000000" },
        { id: 40000000, value: "40000000" },
        { id: 50000000, value: "50000000" },
        { id: 60000000, value: "60000000" },
        { id: 75000000, value: "75000000" },
        { id: 100000000, value: "100000000" },
        { id: 125000000, value: "125000000" },
        { id: 150000000, value: "150000000" },
        { id: 200000000, value: "200000000" },
        { id: 225000000, value: "225000000" },
        { id: 250000000, value: "250000000" },
        { id: 300000000, value: "300000000" },
        { id: 400000000, value: "400000000" },
        { id: 500000000, value: "500000000" },
      ],
      modeLogsDataTable: [
        { text: "User Id", value: "u" },
        //{ text: "User Name", value: "un" },
        { text: "Mode Id", value: "m" },
        { text: "Mode Name", value: "mn" },
        { text: "Free Spin Won Coin", value: "fs" },
        { text: "Total Won Coins", value: "t" },
        { text: "Extra Properties", value: "e" },
        { text: "Spin Result", value: "sr" },
        { text: "Win Type", value: "w" },
        { text: "Bet Level", value: "b" },
        { text: "Candy", value: "c" },
        { text: "Create Date", value: "createDate" },
        { text: "Free Spin", value: "f" },
        { text: "In Game Type", value: "i" },
        { text: "Level", value: "l" },
        { text: "Level Map - Free Spin", value: "lm.freeSpin" },
        { text: "Level Map - Won Coin", value: "lm.wonCoin" },
        { text: "Level Map - Gems", value: "lm.gems" },
        { text: "Level Map - Mini Game", value: "lm.miniGame" },
        { text: "Level Win - Won Coin", value: "lw.wonCoin" },
        { text: "Level Win - Level", value: "lw.level" },
        { text: "Level Win - Next Won Coin", value: "lw.nextWonCoin" },
        { text: "Level Win - Next Free Spin", value: "lw.nextFreeSpin" },
        { text: "Level Win - Target Spin", value: "lw.targetSpin" },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        if (this.options.sortBy.length > 0) this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    pageClick(a) {
      this.currentPage = a;
      this.initialize();
    },
    async extraProperties(item) {
      this.itemExtraProperties = item.e;
      this.dialogExtraProperties = true;
    },
    async spinResult(item) {
      this.itemSpinResult = item.sr;
      this.dialogSpinResult = true;
    },
    async initialize() {
      this.$refs.menu.save(this.dates);
      this.loading = true;
      await this.$store.dispatch("modeLogs/getAll", {
        betLevel: this.betLevelSelect.id,
        size: this.pageItemSelect.id,
        modeType: this.typeSelect.key,
        page: this.currentPage - 1,
        startDate:
          new Date(
            new Date(this.dates[0]).getTime() +
              new Date().getTimezoneOffset() * 60000
          ) / 1000,
        endDate: new Date(this.dates[1]).addDays(1).getTime() / 1000,
        options: this.options,
      });
      this.loading = false;
    },
  },

  async mounted() {
    await this.initialize();
  },
};
</script>

<style>
.card-p {
  width: 142px;
  height: 155px;
  background-size: 142px 155px;
  background-repeat: no-repeat;
  border-radius: 9.5px;
  margin-right: 12px;
  margin-top: 2px;
  margin-bottom: 2px;
}
.lime-card {
  background-image: url(https://bundle.slotbase.net/assets/img/green.png);
}
.green-card {
  background-image: url(https://bundle.slotbase.net/assets/img/green.png);
}
.red-card {
  background-image: url(https://bundle.slotbase.net/assets/img/red.png);
}
.orange-card {
  background-image: url(https://bundle.slotbase.net/assets/img/orange.png);
}
.v-tooltip__content {
  opacity: 1 !important;
}
.jv-container {
  background: #0000005c !important;
}
.jsoneditor-field {
  color: #b7b3c3 !important;
}
a.jsoneditor-value.jsoneditor-url,
div.jsoneditor-value.jsoneditor-url {
  color: #00cfff !important;
}
div.jsoneditor-value.jsoneditor-array,
div.jsoneditor-value.jsoneditor-object {
  color: #b7abab !important;
}
.ace-jsoneditor .ace_variable,
.ace-jsoneditor .ace_gutter-cell {
  color: #fff !important;
}
div.jsoneditor-value.jsoneditor-string {
  color: #00cfff;
}
.ace-jsoneditor .ace_scroller,
.ace-jsoneditor .ace_gutter {
  background: #000 !important;
}
</style>
