<template>
  <mode-log-list-card />
</template>
<script>
import ModeLogListCard from "./components/ModeLogListCard.vue";
export default {
  components: {
    ModeLogListCard,
  },
};
</script>
